<template>
  <v-main>
    <v-container>
      <v-row>
        <v-col>
          <h1 style="color: #455a64; font-weight: 400; font-size: 1.9rem">
            Peer Reviewed Journals
          </h1>
        </v-col>
        <v-col class="text-right">
          <close-window></close-window>
        </v-col>
      </v-row>
      <div>
        <v-expansion-panels focusable v-model="expanded" class="mb-6">
          <v-expansion-panel v-for="(item, i) in mainDataList" :key="i">
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              {{ item.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>About</v-list-item-title>
                  <v-list-item-subtitle class="text-wrap">
                    {{ item.about }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Peer Review</v-list-item-title>
                  <v-list-item-subtitle class="text-wrap">{{
                    item.peer
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-row no-gutters>
                  <v-col cols="12" sm="8">
                    <v-list-item-content>
                      <v-list-item-title>Website</v-list-item-title>
                      <v-list-item-subtitle>
                        <a @click="newWindow(item.website)" target="_blank">
                          {{ removeHttp(item.website) }}
                        </a>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-list-item-content>
                      <v-list-item-title>Founded</v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.founded
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-list-item-content>
                      <v-list-item-title>Impact Factor</v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.impact
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-overlay :value="loading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import config from '../config'
const api = config.api()
export default {
  name: 'Journals',
  metaInfo: {
    meta: [
      {
        name: 'description',
        content: ''
      }
    ],
    title: 'Peer Reviewed Journals'
  },
  data: () => ({
    mainDataList: [],
    zipCodeInput: '',
    loading: false,
    expanded: null
  }),
  watch: {},
  beforeMount() {
    this.loading = true
    this.$axios.get(api.truroAPI + '/JournalsApi').then(res => {
      this.loading = false
      const { data } = res.data
      if (data && data.length > 0) {
        this.mainDataList = data
      }
      this.expandJournal()
    })
  },
  methods: {
    expandJournal() {
      let journal = this.$route.query.name
      this.mainDataList.forEach((item, index) => {
        if (String(journal).trim() == String(item.name).trim()) {
          this.expanded = index
        }
      })
    },
    removeHttp(url) {
      url = url.replace(/^https?:\/\//, '')
      return url
    }
  }
}
</script>

<style scoped>
.text-wrap {
  word-wrap: break-word !important;
}
.v-expansion-panel-header--active {
  color: #1976d2 !important;
}
</style>
